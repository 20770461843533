import React, { useState } from "react";
import { Paper, Box, Grid } from "@mui/material";
import LanguageSelector from "./LanguageSelector";
import ThemeSelector from "./ThemeSelector";
import CustomFields from "./CustomFields";
import EditorPane from "./EditorPane";
import EditorToolbar from "./EditorToolbar";
import OutputDrawer from "./OutputDrawer";
import OutputDrawerSubmit from "./OutputDrawerSubmit"; // Import the new component
import { SelectChangeEvent } from "@mui/material";
import { Language, runCodeData } from "../../constants/EditorUtils";
import { languages } from "../../constants/EditorConstants";


const CodeEditor: React.FC<{ problem: any }> = ({ problem }) => {
  const [language, setLanguage] = useState<Language>({
    label: "C++",
    value: "cpp",
    id: 54,
  });
  const [code, setCode] = useState<string>(
    problem.defaultCode.find((defaultCode:any) => defaultCode.language === language.value)?.code || ""
  );
  const [theme, setTheme] = useState<string>("vs-dark");
  const [customInput, setCustomInput] = useState<string>("");
  const [customOutput, setCustomOutput] = useState<string>("");
  const [showCustomFields, setShowCustomFields] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [drawerContent, setDrawerContent] = useState<runCodeData>({
    language: language,
    code: code,
    input: customInput,
  });
  const [submitDrawerOpen, setSubmitDrawerOpen] = useState<boolean>(false);
  const [submitDrawerContent, setSubmitDrawerContent] = useState<runCodeData>({
    language: language,
    code: code,
    input: customInput,
  });

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    const selectedLanguage: Language = {
      label: event.target.value,
      value: event.target.value,
      id: languages.find((lang) => lang.value === event.target.value)?.id || 54,
    };
  
    const newCode =
      problem.defaultCode.find((defaultCode:any) => defaultCode.language === selectedLanguage.value)?.code ||
      "";
  
    setLanguage(selectedLanguage);
    setCode(newCode); // Update the code state based on the selected language
  
    setDrawerContent((prevContent) => ({
      ...prevContent,
      language: selectedLanguage,
      code: newCode, // Update code in drawer content
      input: customInput,
    }));
  
    setSubmitDrawerContent((prevContent) => ({
      ...prevContent,
      language: selectedLanguage,
      code: newCode, // Update code in submit drawer content
      input: customInput,
    }));
  };
  const handleThemeChange = (event: SelectChangeEvent<string>) => {
    setTheme(event.target.value as string);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomInput(event.target.value);
    setDrawerContent(prevContent => ({
      ...prevContent,
      input: event.target.value
    }));
    setSubmitDrawerContent(prevContent => ({
      ...prevContent,
      input: event.target.value
    }));
  };

  const handleOutputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomOutput(event.target.value);
  };

  const handleDrawerOpen = (content: string) => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleSubmitDrawerOpen = () => {
    setSubmitDrawerOpen(true);
  };

  const handleSubmitDrawerClose = () => {
    setSubmitDrawerOpen(false);
  };

  const resetCode = () => {
    console.log("Reset code");
  };

  const runCode = () => {
    handleDrawerOpen("Running code...");
  };

  const runPublicCases = () => {
    handleDrawerOpen("Running public cases...");
  };

  const submitCode = () => {
    handleSubmitDrawerOpen(); // Open the submit drawer
  };

  const toggleCustomFields = () => {
    setShowCustomFields(!showCustomFields);
  };

  return (
    <Paper
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        p: 2,
        background: "linear-gradient(135deg, #f3f4f7, #e9ebf3)",
        boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
      }}
    >
      <Box sx={{ mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <LanguageSelector
              language={language}
              onChange={handleLanguageChange}
            />
          </Grid>
          <Grid item xs={6}>
            <ThemeSelector theme={theme} onChange={handleThemeChange} />
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{ flexGrow: 1, mb: 2, overflow: "hidden", borderRadius: "10px" }}
      >
        <EditorPane
          drawerContent={drawerContent}
          code={code}
          SetCode={setCode}
          SetDrawerContent={setDrawerContent}
          language={language}
          theme={theme}
        />
      </Box>
      {showCustomFields && (
        <Box sx={{ mt: 2 }}>
          <CustomFields
            customInput={customInput}
            customOutput={customOutput}
            onInputChange={handleInputChange}
            onOutputChange={handleOutputChange}
          />
        </Box>
      )}

      <EditorToolbar
        onRunCode={runCode}
        code={code}
        onRunPublicCases={runPublicCases}
        onSubmitCode={submitCode}
        onResetCode={resetCode}
        
        showCustomFields={showCustomFields}
        onToggleCustomFields={toggleCustomFields}
      />

      <OutputDrawer
        open={drawerOpen}
        content={drawerContent}
        onClose={handleDrawerClose}
      />

      <OutputDrawerSubmit
        open={submitDrawerOpen}
        content={drawerContent}
        onClose={handleSubmitDrawerClose}
      />
    </Paper>
  );
};
export default CodeEditor;
