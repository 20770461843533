import React from "react";
import { Box, Card, Typography, IconButton, Avatar, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import LogoutIcon from "@mui/icons-material/Logout";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";

const ProfileCardContainer = styled(Card)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(3),
  boxShadow: theme.shadows[4],
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  position: "relative",
  marginBottom: theme.spacing(4),
}));

const AvatarWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 120,
  height: 120,
  borderRadius: "50%",
  marginRight: theme.spacing(3),
  overflow: "hidden",
}));

const ProfileDetails = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const InfoRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(1),
}));

const InfoIcon = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginRight: theme.spacing(1),
}));

const EditButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2),
  right: theme.spacing(2),
}));

const LogoutButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  padding: theme.spacing(1, 2),
  fontSize: "0.875rem",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  borderRadius: theme.shape.borderRadius,
}));

interface ProfileCardProps {
  fullname: string;
  email: string;
  picture?: string;
  onLogout: () => void;
  onEdit: () => void;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ fullname, email, picture, onLogout, onEdit }) => {
  return (
    <ProfileCardContainer>
      <AvatarWrapper>
        <Avatar
          src={picture || "https://via.placeholder.com/120"}
          alt={fullname}
          sx={{ width: 120, height: 120 }}
        />
      </AvatarWrapper>
      <ProfileDetails>
        <Typography variant="h5" gutterBottom>
          {fullname}
        </Typography>
        <InfoRow>
          <InfoIcon>
            <PersonIcon />
          </InfoIcon>
          <Typography variant="body1">{fullname}</Typography>
        </InfoRow>
        <InfoRow>
          <InfoIcon>
            <EmailIcon />
          </InfoIcon>
          <Typography variant="body1">{email}</Typography>
        </InfoRow>
        <EditButton color="primary" onClick={onEdit}>
          <EditIcon />
        </EditButton>
      </ProfileDetails>
      <LogoutButton
        variant="contained"
        onClick={onLogout}
        startIcon={<LogoutIcon />}
      >
        Logout
      </LogoutButton>
    </ProfileCardContainer>
  );
};

export default ProfileCard;
