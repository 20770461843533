// src/App.tsx
import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ThemeContextProvider from "./ThemeContext";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import UserManagement from "./pages/admin/users";
import CreateProblem from "./pages/author/problem/createProblem";
import Problems from "./pages/author/problem/problems";
import ProblemPage from "./pages/problem/ProblemPage";
import UpdateProblem from "./pages/author/problem/updateProblem";
import CreateMCQ from "./pages/author/mcq/createMcq";
import MCQs from "./pages/author/mcq/problems";
import UpdateMCQ from "./pages/author/mcq/updateMcq";
import DemoMCQTest from "./components/DemoMCQTest";
import CreateClient from "./pages/admin/client/CreateClient";
import ManageClients from "./pages/admin/client/ManageClients";
import UpdateClient from "./pages/admin/client/UpdateClient";
const LandingPage = React.lazy(() => import("./components/LandingPage"));

const App: React.FC = () => {
  return (
    <ThemeContextProvider>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/admin/users" element={<UserManagement />} />
            <Route path="/admin/client/create" element={<CreateClient />} />
            <Route path="/problem/create" element={<CreateProblem />} />
            <Route path="/problems" element={<Problems />} />
            <Route path="/problem/slug/:slug" element={<ProblemPage />} />
            <Route path="/problem/update/:problemId" element={<UpdateProblem />} />
            <Route path="/mcq/create" element={<CreateMCQ />} />
            <Route path="/mcq/update/:mcqId" element={<UpdateMCQ />} />
            <Route path="/mcqs" element={<MCQs />} />
            <Route path="/demo/mcq-test" element={<DemoMCQTest />} />
            <Route path="/admin/manage-clients" element={<ManageClients />} />
            <Route path="/admin/client/update/:id" element={<UpdateClient />} />
            <Route path="*" element={<LandingPage />} />
          </Routes>
        </Suspense>
      </Router>
    </ThemeContextProvider>
  );
};

export default App;
