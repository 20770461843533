import React from "react";
import { Box, TextField } from "@mui/material";

interface CustomFieldsProps {
  customInput: string;
  customOutput: string;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onOutputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomFields: React.FC<CustomFieldsProps> = ({
  customInput,
  customOutput,
  onInputChange,
  onOutputChange,
}) => (
  <Box sx={{ mb: 2, backgroundColor: "#ffffff", p: 2, borderRadius: "10px" }}>
    <TextField
      label="Custom Input"
      multiline
      rows={4}
      value={customInput}
      onChange={onInputChange}
      fullWidth
      variant="outlined"
      sx={{ mb: 2 }}
    />
    <TextField
      label="Custom Output"
      multiline
      rows={4}
      value={customOutput}
      onChange={onOutputChange}
      fullWidth
      variant="outlined"
    />
  </Box>
);

export default CustomFields;
