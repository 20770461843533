import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useParams } from "react-router-dom";
import moment from "moment";
import { apiService } from "../../services/Service";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTheme } from "@mui/material/styles";

// Define the type for submission data
interface Submission {
  _id: string;
  code: string;
  language: string;
  status: string;
  results: string[];
  createdAt: string;
  time?: number; // Optional: Time in seconds
  memory?: number; // Optional: Memory in KB
}

const ProblemSubmissionsPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>(); // Extract the slug from the URL
  const [submissions, setSubmissions] = useState<Submission[]>([]);
  const [selectedCode, setSelectedCode] = useState<string | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [selectedMemory, setSelectedMemory] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();

  useEffect(() => {
    if (!slug) return;

    // Fetch submissions from the backend API
    const fetchSubmissions = async () => {
      try {
        const response: any = await apiService.get(`/user/submissions/${slug}`);
        setSubmissions(response.data);
      } catch (error) {
        console.error("Error fetching submissions:", error);
      }
    };

    fetchSubmissions();
  }, [slug]);

  const handleOpen = (submission: Submission) => {
    setSelectedCode(submission.code);
    setSelectedLanguage(submission.language);
    setSelectedDate(moment(submission.createdAt).format("MMMM D, YYYY h:mm A"));
    setSelectedTime(submission.time ? `${submission.time.toFixed(2)} secs` : "N/A");
    setSelectedMemory(submission.memory ? `${submission.memory.toFixed(2)} KB` : "N/A");
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleCopyCode = () => {
    if (selectedCode) {
      navigator.clipboard.writeText(selectedCode);
      alert("Code copied to clipboard!");
    }
  };

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{
          marginTop: 2,
          borderRadius: 2,
          boxShadow: 3,
          maxHeight: "80vh",
          overflow: "auto",
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold", backgroundColor: theme.palette.primary.light, color: theme.palette.primary.contrastText }}>
                S.No
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", backgroundColor: theme.palette.primary.light, color: theme.palette.primary.contrastText }}>
                Verdict
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", backgroundColor: theme.palette.primary.light, color: theme.palette.primary.contrastText }}>
                Language
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", backgroundColor: theme.palette.primary.light, color: theme.palette.primary.contrastText }}>
                Time Submitted
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", backgroundColor: theme.palette.primary.light, color: theme.palette.primary.contrastText }}>
                Execution Time
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", backgroundColor: theme.palette.primary.light, color: theme.palette.primary.contrastText }}>
                Memory Usage
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {submissions.map((submission, index) => (
              <TableRow
                key={submission._id}
                hover
                onClick={() => handleOpen(submission)}
                sx={{
                  "&:nth-of-type(odd)": { backgroundColor: theme.palette.background.default },
                  "&:hover": { backgroundColor: theme.palette.primary.light },
                }}
              >
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    sx={{
                      color:
                        submission.status === "Accepted" ? theme.palette.success.main : theme.palette.error.main,
                    }}
                  >
                    {submission.status}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" sx={{ fontStyle: "italic" }}>
                    {submission.language}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{moment(submission.createdAt).fromNow()}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {submission.time ? `${submission.time.toFixed(2)} secs` : "N/A"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {submission.memory ? `${submission.memory.toFixed(2)} KB` : "N/A"}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={open} onClose={handleClose} aria-labelledby="code-modal-title">
      <Box
        sx={{
          width: '90%',
          maxWidth: '700px',
          margin: 'auto',
          marginTop: '5%',
          padding: 4,
          backgroundColor: theme.palette.background.default,
          boxShadow: 24,
          borderRadius: 3,
          maxHeight: '80vh',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography variant="h6" id="code-modal-title" gutterBottom sx={{ color: theme.palette.primary.main }}>
          Submission Details
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="subtitle1">
            <strong>Language:</strong> {selectedLanguage}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Submitted At:</strong> {selectedDate}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Execution Time:</strong> {selectedTime}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Memory Usage:</strong> {selectedMemory}
          </Typography>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            <strong>Code:</strong>
          </Typography>
          <Box
            sx={{
              backgroundColor: '#f7f8fa',
              padding: 2,
              borderRadius: 2,
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
              fontFamily: "'Courier New', Courier, monospace",
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            {selectedCode}
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <Button
            onClick={handleCopyCode}
            variant="contained"
            color="primary"
            startIcon={<ContentCopyIcon />}
            sx={{ flex: 1 }}
          >
            Copy Code
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="secondary"
            sx={{ flex: 1 }}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
    </div>
  );
};

export default ProblemSubmissionsPage;
