import React, { useEffect, useState, useCallback } from "react";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Divider,
} from "@mui/material";
import { Close, Error } from "@mui/icons-material";
import { runCodeData } from "../../constants/EditorUtils";
import { apiService } from "../../services/Service";
import statusIcons from "../../constants/statusIcons";
import { useParams } from "react-router-dom";

interface OutputDrawerSubmitProps {
  open: boolean;
  content: runCodeData;
  onClose: () => void;
}

interface SubmitCodeType {
  languageId: number;
  code: string;
  problemSlug: string;
  language: string;
}

const OutputDrawerSubmit: React.FC<OutputDrawerSubmitProps> = ({
  open,
  content,
  onClose,
}) => {
  const [status, setStatus] = useState<string>("Processing");
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [submissionId, setSubmissionId] = useState<string>("");
  const [result, setResult] = useState<string>("");
  const [testcaseResults, setTestcaseResults] = useState<string[]>([]);
  const [submissionStatus, setSubmissionStatus] =
    useState<string>("Processing");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { slug } = useParams();

  const dataForSubmit: SubmitCodeType = {
    languageId: content.language.id,
    code: content.code,
    problemSlug: slug || "",
    language: content.language.value,
  };

  // Function to handle code submission
  const submitCode = useCallback(() => {
    if (isSubmitting || hasSubmitted) return; // Prevent multiple submissions
    setIsSubmitting(true);

    apiService
      .post("/submit", dataForSubmit)
      .then((res: any) => {
        const { submissionId } = res;
        setSubmissionStatus("Processing");
        setSubmissionId(submissionId);
        setHasSubmitted(true);
      })
      .catch((err) => {
        console.error(err);
        setStatus("Error");
        setResult("Failed to submit your code.");
      })
      .finally(() => {
        setIsSubmitting(false); // Reset the submission state
      });
  }, [dataForSubmit, isSubmitting, hasSubmitted]);

  // Effect to start polling when a submission ID is available
  useEffect(() => {
    if (!submissionId) return;

    const pollSubmissionStatus = () => {
      apiService
        .get(`/submit/status/${submissionId}`)
        .then((res: any) => {
          const { submissionStatuses, overallStatus } = res;
          setSubmissionStatus(overallStatus);
          setTestcaseResults(submissionStatuses);

          if (overallStatus === "In Queue" || overallStatus === "Processing") {
            setTimeout(pollSubmissionStatus, 3000); // Poll every 3 second
          } else {
            setResult(submissionStatuses.join("\n") || "No output");
          }
        })
        .catch((err) => {
          console.error(err);
          setSubmissionStatus("Error");
          setResult("An error occurred while processing your code.");
        });
    };

    pollSubmissionStatus();
  }, [submissionId]);

  // Effect to handle submission on drawer open
  useEffect(() => {
    if (open && !hasSubmitted && !isSubmitting) {
      submitCode();
    }
  }, [open, hasSubmitted, isSubmitting, submitCode]);

  // Effect to reset state when drawer closes
  useEffect(() => {
    if (!open) {
      setHasSubmitted(false);
      setSubmissionStatus("");
      setTestcaseResults([]);
      setResult("");
      setSubmissionId("");
    }
  }, [open]);

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      sx={{ width: "50%", maxHeight: "70vh", overflow: "auto" }}
    >
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          bgcolor: "#f9f9f9",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
              {(statusIcons as Record<string, React.ReactNode>)[
                submissionStatus
              ] || <Error color="error" />}
            </Box>
            <Typography variant="h6" component="div">
              {submissionStatus}
            </Typography>
          </Box>

          <IconButton onClick={onClose} color="inherit">
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          Testcase Results:
        </Typography>
        <Box
          sx={{
            mt: 2,
            p: 2,
            bgcolor: "white",
            borderRadius: 2,
            boxShadow: 1,
            whiteSpace: "pre-wrap",
            border: "1px solid #ddd",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap", // Allows wrapping of the content in the flex container
            overflow: "auto",
            maxHeight: "30vh",
          }}
        >
          {testcaseResults.map((result, index) => (
            <Typography key={index} variant="body2" sx={{ mr: 2 }}>
              {" "}
              {/* Adds margin between elements */}
              Testcase {index + 1}: {result}
            </Typography>
          ))}
        </Box>
      </Box>
    </Drawer>
  );
};

export default OutputDrawerSubmit;
