import React from 'react';
import {
  Box,
  Typography,
  Chip,
  Avatar,
  Paper,
  Grid,
  Tooltip,
  Stack,
  IconButton,
  useTheme,
  Divider,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface Problem {
  title: string;
  difficultyLevel: string;
  description: string;
  constraints: string;
  inputFormat: string;
  outputFormat: string;
  publicTestcases: { input: string; output: string; explanation?: string }[];
  tags: string[];
  author: { fullname: string; picture: string };
}

interface ProblemDetailsProps {
  problem: Problem;
}

const ProblemDetails: React.FC<ProblemDetailsProps> = ({ problem }) => {
  const theme = useTheme();

  const difficultyColor = {
    easy: 'success',
    medium: 'warning',
    hard: 'error',
  }[problem?.difficultyLevel?.toLowerCase()] as
    | 'success'
    | 'warning'
    | 'error'
    | undefined;

  const copyToClipboard = (text: string) => {
    try {
      navigator.clipboard.writeText(text);
      alert('Copied to clipboard!');
    } catch (error) {
      console.error(error);
      alert('Failed to copy to clipboard!');
    }
  };

  const renderSection = (title: string, content: string) => (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        backgroundColor: theme.palette.background.paper,
        borderRadius: '16px',
        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
        mb: 2,
        transition: 'box-shadow 0.3s ease',
        '&:hover': {
          boxShadow: '0px 12px 30px rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
        {title}
      </Typography>
      <Box
        dangerouslySetInnerHTML={{ __html: content || '' }}
        sx={{ fontSize: '1rem', lineHeight: '1.6', color: theme.palette.text.primary }}
      />
    </Paper>
  );

  return (
    <Box
      sx={{
        p: 3,
        maxHeight: '100%',
        mx: 'auto',
        background: theme.palette.background.default,
        boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
        overflow: 'auto',
        borderRadius: '16px',
      }}
    >
      {/* Title and Difficulty */}
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}
        >
          {problem?.title || ''}
        </Typography>
        <Chip
          label={problem?.difficultyLevel || ''}
          color={difficultyColor}
          sx={{
            fontSize: '0.875rem',
            px: 1.5,
            py: 0.5,
            borderRadius: '8px',
          }}
        />
      </Box>

      {/* Description */}
      {renderSection('Description', problem?.description || '')}

      {/* Constraints, Input Format, Output Format */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          {renderSection('Constraints', problem?.constraints || '')}
        </Grid>
        <Grid item xs={12}>
          {renderSection('Input Format', problem?.inputFormat || '')}
        </Grid>
        <Grid item xs={12}>
          {renderSection('Output Format', problem?.outputFormat || '')}
        </Grid>
      </Grid>

      {/* Public Test Cases */}
      <Paper
        elevation={3}
        sx={{
          p: 3,
          mb: 3,
          backgroundColor: theme.palette.background.paper,
          borderRadius: '16px',
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
          Public Test Cases
        </Typography>
        {problem?.publicTestcases?.map((testCase, index) => (
          <Box
            key={index}
            mt={2}
            p={2}
            border="1px solid #ddd"
            borderRadius="12px"
            sx={{
              backgroundColor: '#f9f9f9',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
            }}
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                Input:
              </Typography>
              <Tooltip title="Copy Input">
                <IconButton onClick={() => copyToClipboard(testCase?.input || '')}>
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
            <Box
              sx={{
                p: 1,
                borderRadius: '8px',
                backgroundColor: '#ffffff',
                boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.05)',
                fontSize: '0.9rem',
              }}
            >
              <pre>{testCase?.input || ''}</pre>
            </Box>
            <Divider sx={{ my: 1 }} />
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                Output:
              </Typography>
              <Tooltip title="Copy Output">
                <IconButton onClick={() => copyToClipboard(testCase?.output || '')}>
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
            <Box
              sx={{
                p: 1,
                borderRadius: '8px',
                backgroundColor: '#ffffff',
                boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.05)',
                fontSize: '0.9rem',
              }}
            >
              <pre>{testCase?.output || ''}</pre>
            </Box>
            {testCase?.explanation && (
              <>
                <Divider sx={{ my: 1 }} />
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  Explanation:
                </Typography>
                <Box
                  sx={{
                    p: 1,
                    borderRadius: '8px',
                    backgroundColor: '#ffffff',
                    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.05)',
                    fontSize: '0.9rem',
                  }}
                  dangerouslySetInnerHTML={{ __html: testCase.explanation || '' }}
                />
              </>
            )}
          </Box>
        )) || null}
      </Paper>

      {/* Tags */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
          Tags
        </Typography>
        <Box display="flex" flexWrap="wrap" gap={1}>
          {problem?.tags?.map((tag) => (
            <Chip
              key={tag}
              label={tag}
              variant="outlined"
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                backgroundColor: '#e3f2fd',
                borderColor: '#64b5f6',
                color: '#1976d2',
                '&:hover': {
                  backgroundColor: '#bbdefb',
                },
              }}
            />
          )) || null}
        </Box>
      </Box>

    
    </Box>
  );
};

export default ProblemDetails;
