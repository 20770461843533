import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import { themes } from "../../constants/EditorConstants";


interface ThemeSelectorProps {
  theme: string;
  onChange: (event: SelectChangeEvent<string>) => void;
}

const ThemeSelector: React.FC<ThemeSelectorProps> = ({ theme, onChange }) => (
  <FormControl fullWidth>
    <InputLabel>Theme</InputLabel>
    <Select
      value={theme}
      onChange={onChange}
      sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
    >
      {themes.map((th) => (
        <MenuItem key={th} value={th}>
          {th}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default ThemeSelector;
