import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  IconButton,
  InputAdornment,
  Pagination,
  Button,
} from "@mui/material";
import { apiService } from "../../../services/Service"; // Ensure this path is correct
import { handleAuthError } from "../../../utils/authUtils"; // Ensure this path is correct
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";

// Define interface for client data
interface Client {
  _id: string; // Adjusted to match the _id field
  name: string;
  email: string;
  isApproved: boolean;
}

const ClientList: React.FC = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [search, setSearch] = useState<string>("");
  const [approvalFilter, setApprovalFilter] = useState<
    "all" | "approved" | "unapproved"
  >("all");
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const navigate = useNavigate();

  // Fetch clients data from API
  const fetchClients = async () => {
    setLoading(true);
    try {
      // Construct query parameters
      const queryParams = new URLSearchParams({
        page: page.toString(),
        search: search || "",
        isApproved:
          approvalFilter === "all"
            ? "all"
            : approvalFilter === "approved"
            ? "true"
            : "false",
        limit: "10", // Default limit value
      });

      // Build URL with query parameters
      const url = `/admin/clients?${queryParams.toString()}`;

      // Fetch data from API
      const response = await apiService.get<{ data: Client[]; pages: number }>(
        url
      );

      // Update state with fetched data
      setClients(response.data);
      setTotalPages(response.pages);
    } catch (error: any) {
      console.error("Error fetching clients:", error);
      setSnackbarMessage(
        error.response?.data.error || "Failed to fetch clients"
      );
      setSnackbarOpen(true);
      handleAuthError(
        { error: error.response?.data.error },
        window.location.href
      );
    } finally {
      setLoading(false);
    }
  };

  // Fetch clients when component mounts or when search parameters change
  useEffect(() => {
    fetchClients();
  }, [search, approvalFilter, page]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };

  const handleEditClick = (id: string) => {
    navigate(`/admin/client/update/${id}`);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Client List
      </Typography>

      <Box sx={{ mb: 2, display: "flex", alignItems: "center", gap: 2 }}>
        <TextField
          label="Search by Name or Email"
          variant="outlined"
          fullWidth
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setSearch("")} edge="end">
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <RadioGroup
          row
          value={approvalFilter}
          onChange={(e) =>
            setApprovalFilter(
              e.target.value as "all" | "approved" | "unapproved"
            )
          }
        >
          <FormControlLabel
            value="all"
            control={<Radio color="primary" />}
            label="All Clients"
          />
          <FormControlLabel
            value="approved"
            control={<Radio color="primary" />}
            label="Approved Only"
          />
          <FormControlLabel
            value="unapproved"
            control={<Radio color="primary" />}
            label="Unapproved Only"
          />
        </RadioGroup>
      </Box>

      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Approved</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : clients.length > 0 ? (
              clients.map((client) => (
                
                <TableRow key={client._id}>
                  <TableCell>{client.name}</TableCell>
                  <TableCell>{client.email}</TableCell>
                  <TableCell>{client.isApproved ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleEditClick(client._id)}
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No clients found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(e, value) => setPage(value)}
          color="primary"
        />
      </Box>

      {/* Snackbar for feedback messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Box>
  );
};

export default ClientList;
