import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Grid,
  Box,
  TextField,
  SelectChangeEvent,
  Container,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import RichTextField from "../../../components/RichTextField";
import TestcaseSection from "../../../components/TestcaseSection";
import { Testcase } from "../../../constants/ProblemUtils";
import { ProblemFormData, initialData } from "../../../constants/ProblemUtils";
import { difficultyLevels } from "../../../constants/difficultyLevels";
import { tagOptions } from "../../../constants/tagOptions";
import { ProgrammingLanguage } from "../../../utils/types";
import { apiService } from "../../../services/Service";
import CustomSnackbar from "../../../components/CustomSnackbar";
import { SnackbarMessage } from "../../../customHooks/useSnackbar";
import { handleAuthError } from "../../../utils/authUtils";

const languageNames: Record<ProgrammingLanguage, string> = {
  [ProgrammingLanguage.JAVASCRIPT]: "JavaScript",
  [ProgrammingLanguage.C]: "C",
  [ProgrammingLanguage.CPP]: "C++",
  [ProgrammingLanguage.JAVA]: "Java",
  [ProgrammingLanguage.PYTHON]: "Python",
};

const UpdateProblem: React.FC = () => {
  const { problemId } = useParams<{ problemId: string }>();
  const [formData, setFormData] = useState<ProblemFormData>(initialData);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessageInfo, setSnackbarMessageInfo] =
    useState<SnackbarMessage | null>(null);

  useEffect(() => {
    if (problemId) {
      apiService
        .get(`/problem/${problemId}`)
        .then((response: any) => {
          setFormData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setSnackbarMessageInfo({
            type: "error",
            message:
              error.response?.data.error || "Failed to fetch problem data.",
          });
          setSnackbarOpen(true);
          handleAuthError(
            { error: error.response?.data.error },
            window.location.href
          );
        });
    }
  }, [problemId]);

  const handleOpenSnackbar = () => {
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessageInfo(null);
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleRichTextChange =
    (
      field: keyof Omit<
        ProblemFormData,
        "publicTestcases" | "privateTestcases" | "defaultCode"
      >
    ) =>
    (content: string) => {
      setFormData((prevData) => ({ ...prevData, [field]: content }));
    };

  const handleTestcaseChange = (
    type: "public" | "private",
    index: number,
    field: keyof Testcase,
    value: string
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [`${type}Testcases`]: prevData[`${type}Testcases`].map((testcase, i) =>
        i === index ? { ...testcase, [field]: value } : testcase
      ),
    }));
  };

  const handleTagChange = (e: SelectChangeEvent<string[]>) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      tags: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleAddTestcase = (type: "public" | "private") => {
    setFormData((prevData) => ({
      ...prevData,
      [`${type}Testcases`]: [
        ...prevData[`${type}Testcases`],
        { input: "", output: "", explanation: "" },
      ],
    }));
  };

  const handleRemoveTestcase = (type: "public" | "private", index: number) => {
    setFormData((prevData) => ({
      ...prevData,
      [`${type}Testcases`]: prevData[`${type}Testcases`].filter(
        (_, i) => i !== index
      ),
    }));
  };

  const handleCodeChange = (language: ProgrammingLanguage, code: string) => {
    setFormData((prevData) => ({
      ...prevData,
      defaultCode: prevData.defaultCode.map((defaultCode) =>
        defaultCode.language === language
          ? { ...defaultCode, code }
          : defaultCode
      ),
    }));
  };

  const handleSubmit = () => {
    setLoading(true);
    apiService
      .put(`/problem/${problemId}`, formData)
      .then((response) => {
        setSnackbarMessageInfo({
          type: "success",
          message: "Problem updated successfully!",
        });
        setSnackbarOpen(true);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        setSnackbarMessageInfo({
          type: "error",
          message: error.response?.data.error || "Failed to update problem.",
        });
        setSnackbarOpen(true);
        handleAuthError(
          { error: error.response?.data.error },
          window.location.href
        );
      });
  };

  if (loading) {
    return (
      <Container maxWidth="md">
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          padding: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#f5f5f5",
          borderRadius: 4,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          marginBottom: 4,
          marginTop: 4,
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "800px",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <Typography variant="h4" gutterBottom align="center">
          Update Problem
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Slug"
              name="slug"
              value={formData.slug}
              onChange={handleInputChange}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Difficulty Level</InputLabel>
              <Select
                name="difficultyLevel"
                value={formData.difficultyLevel}
                onChange={handleInputChange}
                label="Difficulty Level"
              >
                {difficultyLevels.map((level) => (
                  <MenuItem key={level} value={level}>
                    {level}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Tags</InputLabel>
              <Select
                name="tags"
                multiple
                value={formData.tags}
                onChange={handleTagChange}
                renderValue={(selected) => (selected as string[]).join(", ")}
                label="Tags"
              >
                {tagOptions.map((tag) => (
                  <MenuItem key={tag} value={tag}>
                    {tag}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <RichTextField
              label="Description"
              value={formData.description}
              onChange={handleRichTextChange("description")}
            />
          </Grid>
          <Grid item xs={12}>
            <RichTextField
              label="Input Format"
              value={formData.inputFormat}
              onChange={handleRichTextChange("inputFormat")}
            />
          </Grid>
          <Grid item xs={12}>
            <RichTextField
              label="Output Format"
              value={formData.outputFormat}
              onChange={handleRichTextChange("outputFormat")}
            />
          </Grid>
          <Grid item xs={12}>
            <RichTextField
              label="Constraints"
              value={formData.constraints}
              onChange={handleRichTextChange("constraints")}
            />
          </Grid>

          <Grid item xs={12}>
            <TestcaseSection
              title="Public Testcases"
              testcases={formData.publicTestcases}
              onAdd={() => handleAddTestcase("public")}
              onRemove={(index) => handleRemoveTestcase("public", index)}
              onChange={(index, field, value) =>
                handleTestcaseChange("public", index, field, value)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TestcaseSection
              title="Private Testcases"
              testcases={formData.privateTestcases}
              onAdd={() => handleAddTestcase("private")}
              onRemove={(index) => handleRemoveTestcase("private", index)}
              onChange={(index, field, value) =>
                handleTestcaseChange("private", index, field, value)
              }
            />
          </Grid>

          {/* Default Code Section */}
          <Grid item xs={12}>
            <Typography variant="h6">Default Code</Typography>
            {formData.defaultCode.map((defaultCode) => (
              <Box key={defaultCode.language} sx={{ marginBottom: 2 }}>
                <Typography variant="subtitle1">
                  {languageNames[defaultCode.language]}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  value={defaultCode.code}
                  onChange={(e) =>
                    handleCodeChange(defaultCode.language, e.target.value)
                  }
                  variant="outlined"
                  sx={{ marginTop: 1 }}
                />
              </Box>
            ))}
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={loading}
            >
              Update Problem
            </Button>
          </Grid>
        </Grid>
      </Box>

      <CustomSnackbar
        open={snackbarOpen}
        handleClose={handleCloseSnackbar}
        messageInfo={snackbarMessageInfo}
      />
    </Container>
  );
};

export default UpdateProblem;
