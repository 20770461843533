import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import { languages } from "../../constants/EditorConstants";

interface Language {
  label:string,
  id:number,
  value:string,
}
interface LanguageSelectorProps {
  language: Language;
  onChange: (event: SelectChangeEvent<string>) => void;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  language,
  onChange,
}) => (
  <FormControl fullWidth>
    <InputLabel>Language</InputLabel>
    <Select
      value={language.value}
      onChange={onChange}
      sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
    >
      {languages.map((lang) => (
        <MenuItem key={lang.value} value={lang.value}>
          {lang.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
export default LanguageSelector;
