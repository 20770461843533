import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig, AxiosError, AxiosHeaders } from 'axios';

class ApiService {
  private axiosInstance: AxiosInstance;

  constructor(baseURL: string = '/api/') {  // Default baseURL to localhost:3000
    this.axiosInstance = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Add a request interceptor to include the Bearer token
    this.axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const token = localStorage.getItem('jwt_token');
        if (token) {
          if (config.headers) {
            config.headers.set('Authorization', `Bearer ${token}`);
          } else {
            config.headers = new AxiosHeaders({ Authorization: `Bearer ${token}` });
          }
        }
        return config;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );
  }

  // Generic GET method
  public async get<T>(url: string, config?: InternalAxiosRequestConfig): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.get<T>(url, config);
      return response.data;
    } catch (error) {
      this.handleError(error);
      throw error;  // Ensure the function returns or throws
    }
  }

  // Generic POST method
  public async post<T>(url: string, data: any, config?: InternalAxiosRequestConfig): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.post<T>(url, data, config);
      return response.data;
    } catch (error) {
      this.handleError(error);
      throw error;  // Ensure the function returns or throws
    }
  }

  // Generic PUT method
  public async put<T>(url: string, data: any, config?: InternalAxiosRequestConfig): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.put<T>(url, data, config);
      return response.data;
    } catch (error) {
      this.handleError(error);
      throw error;  // Ensure the function returns or throws
    }
  }

  // Generic DELETE method
  public async delete<T>(url: string, config?: InternalAxiosRequestConfig): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.delete<T>(url, config);
      return response.data;
    } catch (error) {
      this.handleError(error);
      throw error;  // Ensure the function returns or throws
    }
  }

  // Error handling
  private handleError(error: any): void {
    // You can add more sophisticated error handling here
    console.error('API call failed. Error:', error);
    throw error;
  }
}

export default ApiService;
