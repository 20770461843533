import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Grid,
  Paper,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Badge,
  AppBar,
  Toolbar,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { CheckCircleOutline, RadioButtonUnchecked, Timer, ArrowBack, ArrowForward, Done } from '@mui/icons-material';

const demoQuestions = [
  { 
    question: 'What is the capital of France?', 
    options: ['Paris', 'London', 'Berlin', 'Madrid'], 
    correctAnswerText: 'Paris', 
    difficultyLevel: 'Easy', 
    tags: ['Geography'], 
    author: 'Demo Author', 
    isActive: true, 
    explanation: 'Paris is the capital city of France.' 
  },
  { 
    question: 'What is 2 + 2?', 
    options: ['3', '4', '5', '6'], 
    correctAnswerText: '4', 
    difficultyLevel: 'Easy', 
    tags: ['Math'], 
    author: 'Demo Author', 
    isActive: true, 
    explanation: '2 + 2 equals 4.' 
  },
  { 
    question: 'Which planet is known as the Red Planet?', 
    options: ['Earth', 'Mars', 'Jupiter', 'Saturn'], 
    correctAnswerText: 'Mars', 
    difficultyLevel: 'Medium', 
    tags: ['Science'], 
    author: 'Demo Author', 
    isActive: true, 
    explanation: 'Mars is known as the Red Planet due to its reddish appearance caused by iron oxide on its surface.' 
  },
  { 
    question: 'Who wrote "To Kill a Mockingbird"?', 
    options: ['Harper Lee', 'Mark Twain', 'Ernest Hemingway', 'F. Scott Fitzgerald'], 
    correctAnswerText: 'Harper Lee', 
    difficultyLevel: 'Medium', 
    tags: ['Literature'], 
    author: 'Demo Author', 
    isActive: true, 
    explanation: '"To Kill a Mockingbird" was written by Harper Lee and published in 1960.' 
  },
  { 
    question: 'What is the boiling point of water at sea level in Celsius?', 
    options: ['90°C', '100°C', '110°C', '120°C'], 
    correctAnswerText: '100°C', 
    difficultyLevel: 'Easy', 
    tags: ['Science'], 
    author: 'Demo Author', 
    isActive: true, 
    explanation: 'The boiling point of water at sea level is 100°C.' 
  },
  { 
    question: 'In which year did the Titanic sink?', 
    options: ['1912', '1905', '1915', '1920'], 
    correctAnswerText: '1912', 
    difficultyLevel: 'Hard', 
    tags: ['History'], 
    author: 'Demo Author', 
    isActive: true, 
    explanation: 'The Titanic sank on April 15, 1912, after hitting an iceberg during its maiden voyage.' 
  },
  { 
    question: 'What is the chemical symbol for Gold?', 
    options: ['Au', 'Ag', 'Pb', 'Fe'], 
    correctAnswerText: 'Au', 
    difficultyLevel: 'Easy', 
    tags: ['Chemistry'], 
    author: 'Demo Author', 
    isActive: true, 
    explanation: 'The chemical symbol for Gold is Au, derived from the Latin word "Aurum".' 
  },
];

const DemoMCQTest: React.FC = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<{ [key: number]: string }>({});
  const [submitted, setSubmitted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(300); // 30 seconds for demonstration
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    // Countdown timer
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          confirmSubmit(); // Automatically submit when time runs out
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleAnswerChange = (questionIndex: number, answer: string) => {
    setAnswers((prevAnswers) => ({ ...prevAnswers, [questionIndex]: answer }));
  };

  const handleSubmit = () => {
    setOpenConfirmationDialog(true); // Open the confirmation dialog
  };

  const confirmSubmit = () => {
    setSubmitted(true); // Set the test as submitted
    setOpenConfirmationDialog(false); // Close the confirmation dialog if open
  };

  const handleNext = () => {
    setCurrentQuestionIndex((prevIndex) => Math.min(prevIndex + 1, demoQuestions.length - 1));
  };

  const handlePrevious = () => {
    setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleQuestionSelect = (index: number) => {
    setCurrentQuestionIndex(index);
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  if (submitted) {
    return (
      <Container>
        <Typography variant="h4" align="center" mt={5}>
          Test Completed!
        </Typography>
        <Typography variant="body1" align="center" mt={2}>
          Thank you for taking the test. You have successfully submitted your answers.
        </Typography>
      </Container>
    );
  }

  return (
    <Box display="flex">
      {/* Left Sidebar */}
      <Drawer variant="permanent" sx={{ width: 240, flexShrink: 0, [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' } }}>
        <Box sx={{ padding: 2 }}>
          <Typography variant="h6" align="center" gutterBottom>
            Question Navigator
          </Typography>
          <Divider />
          <List>
            {demoQuestions.map((question, index) => (
              <ListItem
                button
                key={index}
                onClick={() => handleQuestionSelect(index)}
                selected={index === currentQuestionIndex}
                sx={{ backgroundColor: index === currentQuestionIndex ? 'rgba(0, 123, 255, 0.1)' : 'transparent' }}
              >
                <Badge color="primary" variant="dot" invisible={!answers[index]}>
                  <ListItemText
                    primary={`Q${index + 1}`}
                    secondary={answers[index] ? <CheckCircleOutline color="success" /> : <RadioButtonUnchecked color="action" />}
                  />
                </Badge>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>

      {/* Main Content */}
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: `calc(100% - 240px)` }}>
        <AppBar position="static" color="default" elevation={0}>
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              MCQ Test
            </Typography>
            <Button startIcon={<Timer />} variant="outlined" color="secondary">
              Time Left: {formatTime(timeLeft)}
            </Button>
          </Toolbar>
        </AppBar>
        <Container>
          {/* Test Details */}
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={3}>
            <Typography variant="body1">
              Question {currentQuestionIndex + 1} of {demoQuestions.length}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Difficulty: {demoQuestions[currentQuestionIndex].difficultyLevel}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Author: {demoQuestions[currentQuestionIndex].author}
            </Typography>
          </Box>

          {/* Instructions */}
          <Box mb={3}>
            <Typography variant="h6" gutterBottom>
              Instructions:
            </Typography>
            <Typography variant="body2">
              1. Read each question carefully.
              <br />
              2. Select the correct answer from the options provided.
              <br />
              3. Click 'Submit' when you have answered all the questions.
            </Typography>
          </Box>

          {/* Question and Options */}
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6" gutterBottom>
              {demoQuestions[currentQuestionIndex].question}
            </Typography>
            <RadioGroup
              value={answers[currentQuestionIndex] || ''}
              onChange={(e) => handleAnswerChange(currentQuestionIndex, e.target.value)}
            >
              {demoQuestions[currentQuestionIndex].options.map((option: string, idx: number) => (
                <FormControlLabel
                  key={idx}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </Paper>

          {/* Navigation Buttons */}
          <Box mt={3}>
  <Grid container spacing={2} justifyContent="space-between">
    <Grid item>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ArrowBack />}
            onClick={handlePrevious}
            disabled={currentQuestionIndex === 0}
          >
            Previous
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            endIcon={<ArrowForward />}
            onClick={handleNext}
            disabled={currentQuestionIndex === demoQuestions.length - 1}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <Button
        variant="contained"
        color="success"
        endIcon={<Done />}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </Grid>
  </Grid>
</Box>

        </Container>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
      >
        <DialogTitle>Submit Test</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to submit the test? Once submitted, you will not be able to change your answers.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmationDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmSubmit} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DemoMCQTest;
