import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { apiService } from "../../services/Service";
import { AxiosError } from "axios";
import { roles } from "../../constants/roles";
import { User, UsersResponse, ErrorResponse } from "../../utils/types";
import {
  Container,
  Typography,
  TextField,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  TablePagination,
  SelectChangeEvent,
} from "@mui/material";
import { handleAuthError } from "../../utils/authUtils";

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const queryParams = new URLSearchParams({
          page: currentPage.toString(),
          search: search || "",
          role: role || "",
        });

        const url = `/admin/user?${queryParams.toString()}`;
        const response = await apiService.get<UsersResponse>(url);
        setUsers(response.users);
        setTotalPages(response.totalPages);
      } catch (err) {
        const error = err as AxiosError<ErrorResponse>;
        const errorResponse = error.response?.data;

        if (errorResponse) {
          handleAuthError({ error: errorResponse.error }, window.location.href);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, [currentPage, search, role]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handleRoleChange = (e: SelectChangeEvent<string>) => {
    setRole(e.target.value as string);
    setCurrentPage(1);
  };

  const handleUserRoleChange = async (userId: string, newRole: string) => {
    try {
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === userId ? { ...user, role: newRole } : user
        )
      );
      await apiService.put(`/admin/user/${userId}/role`, { role: newRole });
    } catch (error) {
      console.error("Failed to update role:", error);
    }
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setCurrentPage(newPage + 1); // MUI's TablePagination is 0-indexed
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" align="center" gutterBottom>
        User Management
      </Typography>

      {/* Search and Filter Section */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Box display="flex" alignItems="center">
          <TextField
            variant="outlined"
            placeholder="Search users..."
            value={search}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: <FaSearch style={{ color: "#9e9e9e" }} />,
            }}
            sx={{ mr: 2 }}
          />
          <Select
            value={role}
            onChange={handleRoleChange}
            displayEmpty
            variant="outlined"
            sx={{ minWidth: 120 }}
          >
            <MenuItem value="">All Roles</MenuItem>
            {roles.map((roleOption) => (
              <MenuItem key={roleOption.value} value={roleOption.value}>
                {roleOption.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>

      {/* User List Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Picture</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : users.length > 0 ? (
              users.map((user) => (
                <TableRow key={user._id}>
                  <TableCell>
                    <img
                      src={user.picture || "https://via.placeholder.com/100"}
                      alt={user.fullname}
                      style={{ width: 60, borderRadius: "50%" }}
                    />
                  </TableCell>
                  <TableCell>{user.fullname}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <Select
                      value={user.role}
                      onChange={(e) =>
                        handleUserRoleChange(user._id, e.target.value as string)
                      }
                      variant="outlined"
                    >
                      {roles.map((roleOption) => (
                        <MenuItem
                          key={roleOption.value}
                          value={roleOption.value}
                        >
                          {roleOption.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No users found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Controls */}
      <Box display="flex" justifyContent="center" mt={4}>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={totalPages * 10} // Assuming 10 users per page
          rowsPerPage={10}
          page={currentPage - 1}
          onPageChange={handlePageChange}
          labelRowsPerPage=""
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} of ${count}`
          }
        />
      </Box>
    </Container>
  );
};

export default UserManagement;
