import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Grid,
  Box,
  SelectChangeEvent,
  Container,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { apiService } from "../../../services/Service";
import CustomSnackbar from "../../../components/CustomSnackbar";
import { SnackbarMessage } from "../../../customHooks/useSnackbar";
import { difficultyLevels } from "../../../constants/difficultyLevels";
import { tagOptions } from "../../../constants/tagOptions";
import { handleAuthError } from "../../../utils/authUtils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

interface MCQFormData {
  question: string;
  options: string[];
  correctAnswerText: string;
  difficultyLevel: string;
  tags: string[];
  explanation?: string;
}

const initialMCQData: MCQFormData = {
  question: "",
  options: ["", ""], // Minimum two options
  correctAnswerText: "",
  difficultyLevel: "",
  tags: [],
  explanation: "",
};

const UpdateMCQ: React.FC = () => {
  const { mcqId } = useParams<{ mcqId: string }>();
  const [formData, setFormData] = useState<MCQFormData>(initialMCQData);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessageInfo, setSnackbarMessageInfo] = useState<SnackbarMessage | null>(null);

  useEffect(() => {
    if (mcqId) {
      apiService.get(`/mcq/${mcqId}`)
        .then((response: any) => {
          setFormData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setSnackbarMessageInfo({
            type: "error",
            message: error.response?.data.error || "Failed to fetch MCQ data.",
          });
          setSnackbarOpen(true);
          handleAuthError({ error: error.response?.data.error }, window.location.href);
        });
    }
  }, [mcqId]);

  const handleOpenSnackbar = () => {
    setSnackbarMessageInfo({
      type: "success",
      message: "MCQ updated successfully!",
    });
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessageInfo(null);
  };

  const handleEditorChange = (value: string, field: keyof MCQFormData) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleOptionsChange = (index: number, value: string) => {
    setFormData((prevData) => {
      const updatedOptions = [...prevData.options];
      updatedOptions[index] = value;
      return { ...prevData, options: updatedOptions };
    });
  };

  const handleAddOption = () => {
    setFormData((prevData) => ({
      ...prevData,
      options: [...prevData.options, ""],
    }));
  };

  const handleRemoveOption = (index: number) => {
    setFormData((prevData) => ({
      ...prevData,
      options: prevData.options.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = () => {
    setLoading(true);
    apiService.put(`/mcq/${mcqId}`, formData)
      .then(() => {
        setLoading(false);
        handleOpenSnackbar();
      })
      .catch((error: any) => {
        setLoading(false);
        setSnackbarMessageInfo({
          type: 'error',
          message: error.response.data.error || "Failed to update MCQ",
        });
        setSnackbarOpen(true);
        handleAuthError({ error: error.response?.data.error }, window.location.href);
      });
  };

  if (loading) {
    return (
      <Container maxWidth="md">
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          padding: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#f5f5f5",
          borderRadius: 4,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          marginBottom: 4,
          marginTop: 4,
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "800px",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <Typography variant="h4" gutterBottom align="center">
          Update MCQ
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ReactQuill
              value={formData.question}
              onChange={(value:any) => handleEditorChange(value, 'question')}
              theme="snow"
              placeholder="Enter the question here..."
            />
          </Grid>

          {formData.options.map((option, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <ReactQuill
                value={option}
                onChange={(value:any) => handleOptionsChange(index, value)}
                theme="snow"
                placeholder={`Enter Option ${index + 1}...`}
              />
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleRemoveOption(index)}
                sx={{ mt: 1 }}
              >
                Remove Option
              </Button>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddOption}
            >
              Add Option
            </Button>
          </Grid>

          <Grid item xs={12}>
            <ReactQuill
              value={formData.correctAnswerText}
              onChange={(value:any) => handleEditorChange(value, 'correctAnswerText')}
              theme="snow"
              placeholder="Enter the correct answer here..."
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Difficulty Level</InputLabel>
              <Select
                name="difficultyLevel"
                value={formData.difficultyLevel}
                onChange={(e: SelectChangeEvent<string>) => handleEditorChange(e.target.value, 'difficultyLevel')}
                label="Difficulty Level"
              >
                {difficultyLevels.map((level) => (
                  <MenuItem key={level} value={level}>
                    {level}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Tags</InputLabel>
              <Select
                name="tags"
                multiple
                value={formData.tags}
                onChange={(e: SelectChangeEvent<string[]>) => handleEditorChange(Array.isArray(e.target.value) ? e.target.value.join(', ') : e.target.value, 'tags')}
                renderValue={(selected) => (selected as string[]).join(", ")}
                label="Tags"
              >
                {tagOptions.map((tag) => (
                  <MenuItem key={tag} value={tag}>
                    {tag}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <ReactQuill
              value={formData.explanation || ""}
              onChange={(value:any) => handleEditorChange(value, 'explanation')}
              theme="snow"
              placeholder="Enter explanation (optional)..."
            />
          </Grid>
        </Grid>

        {!loading ? (
          <Box sx={{ marginTop: 4 }}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Box>
        ) : (
          <CircularProgress />
        )}
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        messageInfo={snackbarMessageInfo}
        handleClose={handleCloseSnackbar}
      />
    </Container>
  );
};

export default UpdateMCQ;
