import React, { useEffect, useState, Suspense, lazy } from "react";
import { Box, Container, Typography, CircularProgress, IconButton, Paper } from "@mui/material";
import ProfileCard from "../components/ProfileCard";
import { apiService } from "../services/Service";
import { ErrorResponse } from "../utils/types";
import { AxiosError } from "axios";
import { handleAuthError } from "../utils/authUtils";
import NotificationsIcon from "@mui/icons-material/Notifications";

// Lazy loading dashboards
const AdminDashboard = lazy(() => import("../components/dashboards/AdminDashboard"));
const AuthorDashboard = lazy(() => import("../components/dashboards/AuthorDashboard"));

interface UserProfile {
  id: string;
  fullname: string;
  email: string;
  picture?: string;
  role: string;
}

interface UserProfileResponse {
  user: UserProfile;
}

const ProfileDashboard: React.FC = () => {
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [tabIndex, setTabIndex] = useState<number>(0);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response: UserProfileResponse = await apiService.get("/user/profile");
        setProfile(response.user);
      } catch (err: any) {
        const error = err as AxiosError<ErrorResponse>;
        const errorResponse = error.response?.data;
        if (errorResponse) {
          handleAuthError({ error: errorResponse.error }, window.location.href);
        }
        setError("Failed to load profile data.");
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("jwt_token");
    window.location.href = "/login";
  };

  const handleEdit = () => {
    // Logic for editing profile
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  if (loading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  if (error)
    return (
      <Box textAlign="center" color="error.main" mt={4}>
        {error}
      </Box>
    );

  const renderDashboard = () => {
    switch (profile?.role) {
      case "admin":
        return (
          <Suspense fallback={<CircularProgress />}>
            <AdminDashboard />
          </Suspense>
        );
      case "author":
        return (
          <Suspense fallback={<CircularProgress />}>
            <AuthorDashboard />
          </Suspense>
        );
      default:
        return <Typography>No Dashboard Available</Typography>;
    }
  };

  return (
    <Container>
      <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Dashboard</Typography>
          <IconButton color="primary">
            <NotificationsIcon />
          </IconButton>
        </Box>
      </Paper>
      {profile ? (
        <>
          <ProfileCard
            fullname={profile.fullname}
            email={profile.email}
            picture={profile.picture}
            onLogout={handleLogout}
            onEdit={handleEdit}
          />
          {renderDashboard()}
        </>
      ) : (
        <Typography variant="h6" color="error">
          No profile data available.
        </Typography>
      )}
    </Container>
  );
};

export default ProfileDashboard;
