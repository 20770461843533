// src/components/Login.tsx
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaGoogle } from "react-icons/fa";
import { useGoogleLogin } from "@react-oauth/google";
import { apiService } from "../services/Service";
import {
  Button,
  TextField,
  Typography,
  Box,
  Container,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Logo from "../components/Logo";

interface GoogleLoginResponse {
  access_token: string;
}

interface ApiResponse {
  token: string;
  user: {
    email: string;
    name: string;
    picture: string;
  };
}

const LoginContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  width: "100vw", // Ensures it takes full width of the viewport
  padding: 0, // Removes default padding
}));

const LoginPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: "400px",
  width: "100%",
  textAlign: "center",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const Login: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [redirectUrl, setRedirectUrl] = useState<string>("/profile"); // Default to /profile if no redirect URL is provided

  useEffect(() => {
    // Extract the redirect URL from the query parameters
    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get("redirect");

    if (redirect) {
      setRedirectUrl(redirect);
    }
  }, [location.search]);

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (googleResponse: GoogleLoginResponse) => {
      try {
        const response: ApiResponse = await apiService.post("/auth/google", {
          token: googleResponse.access_token,
        });

        // Store the token in local storage
        localStorage.setItem("jwt_token", response.token);
        // Store the user data in local storage
        localStorage.setItem("user", JSON.stringify(response.user));

        window.location.href = redirectUrl;
      } catch (error) {
        console.error("Error during Google Login:", error);
      }
    },
    onError: () => {
      console.error("Google Login Failed");
    },
  });

  return (
    <LoginContainer>
      <LoginPaper>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1rem",
            alignItems: "center",
            color: "black",
          }}
        >
          <div>
            {" "}
            <Logo />
          </div>
        </div>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => handleGoogleLogin()}
          startIcon={<FaGoogle color="red" size={20} />}
          sx={{ mb: 2 }}
        >
          Login with Google
        </Button>

        <form>
          <TextField
            label="Email Address"
            type="email"
            fullWidth
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            variant="outlined"
            disabled
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled
            sx={{ mt: 2 }}
          >
            Log In
          </Button>
        </form>

        <Box mt={2}>
          <Typography variant="body2">
            Don't have an account?{" "}
            <Link to="/" style={{ textDecoration: "none", color: "#1976d2" }}>
              Sign Up
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link to="/" style={{ textDecoration: "none", color: "#1976d2" }}>
              Forgot Password?
            </Link>
          </Typography>
        </Box>
      </LoginPaper>
    </LoginContainer>
  );
};

export default Login;
