
export interface User {
    _id: string;
    fullname: string;
    email: string;
    role: string;
    picture: string;
  }
  
 export  interface UsersResponse {
    users: User[];
    totalPages: number;
    currentPage: number;
    totalUsers: number;
  }
  
  export interface ErrorResponse {
    error: string;
  }

  export enum UserRole {
    Unknown = "unknown",
    Client = "client",
    Admin = "admin",
    Student = "student",
    Author = "author",
  }
  
  export enum DifficultyLevel {
    EASY = "easy",
    MEDIUM = "medium",
    HARD = "hard",
  }
  
  export enum ProgrammingLanguage {
    JAVASCRIPT = "javascript",
    C = "c",
    CPP = "cpp",
    JAVA = "java",
    PYTHON = "python",
  }
  