import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
  palette: {
    primary: {
      main: '#0ea275', // Custom primary color
      light: '#3cd2a0', // Light version for hover or lighter backgrounds
      dark: '#007a5f', // Darker version for active states
      contrastText: '#ffffff', // Text color on top of primary color
    },
    secondary: {
      main: '#f6b80a', // Custom secondary color
      light: '#f8d774', // Light version for hover or lighter backgrounds
      dark: '#c68c00', // Darker version for active states
      contrastText: '#000000', // Text color on top of secondary color
    },
    background: {
      default: '#f4f4f4', // Background color for the entire app
      paper: '#ffffff', // Background color for paper components
    },
    text: {
      primary: '#333333', // Primary text color
      secondary: '#666666', // Secondary text color
      
    },
    error: {
      main: '#f44336', // Custom error color
      light: '#ff7961', // Light version for lighter backgrounds
      dark: '#ba000d', // Darker version for active states
      contrastText: '#ffffff', // Text color on top of error color
    },
    warning: {
      main: '#ff9800', // Custom warning color
      light: '#ffb74d', // Light version for lighter backgrounds
      dark: '#c66900', // Darker version for active states
      contrastText: '#000000', // Text color on top of warning color
    },
    info: {
      main: '#2196f3', // Custom info color
      light: '#6ab7ff', // Light version for lighter backgrounds
      dark: '#0069c0', // Darker version for active states
      contrastText: '#ffffff', // Text color on top of info color
    },
    success: {
      main: '#4caf50', // Custom success color
      light: '#80e27e', // Light version for lighter backgrounds
      dark: '#087f23', // Darker version for active states
      contrastText: '#ffffff', // Text color on top of success color
    },
  },
  // Add other theme customizations here if needed
});

export default theme;
