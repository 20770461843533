import React from "react";
import { Box, Button, IconButton, FormControlLabel, Switch } from "@mui/material";
import { PlayArrow, Public, CheckCircle, Refresh } from "@mui/icons-material";

interface EditorToolbarProps {
  code:string;
  onRunCode: () => void;
  onRunPublicCases: () => void;
  onSubmitCode: () => void;
  onResetCode: () => void;
  showCustomFields: boolean;
  onToggleCustomFields: () => void;
}

const EditorToolbar: React.FC<EditorToolbarProps> = ({
  code,
  onRunCode,
  onRunPublicCases,
  onSubmitCode,
  onResetCode,
  showCustomFields,
  onToggleCustomFields,
}) => (
  <Box
    sx={{
      mb: 4,
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      gap: 1,
      padding: 1,
    }}
  >
    <Button
      variant="contained"
      color="primary"
      onClick={onRunCode}
      disabled={code === "" || code.trim() === ""}
      startIcon={<PlayArrow />}
    >
      Run
    </Button>
    <Button
     disabled
      variant="contained"
      color="secondary"
      onClick={onRunPublicCases}
      startIcon={<Public />}
    >
      Run Public Cases
    </Button>
    <Button
    disabled={code === "" || code.trim() === ""}
      variant="contained"
      color="success"
      onClick={onSubmitCode}
      startIcon={<CheckCircle />}
    >
      Submit
    </Button>
    <Box sx={{ flexGrow: 1 }} /> {/* Spacer */}
    <IconButton onClick={onResetCode} color="primary">
      <Refresh />
    </IconButton>
    <FormControlLabel
      control={
        <Switch
          checked={showCustomFields}
          onChange={onToggleCustomFields}
          color="primary"
        />
      }
      label="Show Custom Input/Output"
      sx={{ ml: 1, color: "#333333" }}
    />
  </Box>
);

export default EditorToolbar;
