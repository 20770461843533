import React, { useEffect, useState, useCallback } from "react";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Divider,
} from "@mui/material";
import { Close, CheckCircle, Error, HourglassEmpty } from "@mui/icons-material";
import { runCodeData } from "../../constants/EditorUtils";
import { apiService } from "../../services/Service";
import statusIcons from "../../constants/statusIcons";

interface OutputDrawerProps {
  open: boolean;
  content: runCodeData;
  onClose: () => void;
}

interface RunCodeType {
  language_id: number;
  source_code: string;
  stdin: string;
}

const OutputDrawer: React.FC<OutputDrawerProps> = ({
  open,
  content,
  onClose,
}) => {
  const [status, setStatus] = useState<string>("");
  const [submissionId, setSubmissionId] = useState<string>("");
  const [result, setResult] = useState<string>("");
  const [hasRun, setHasRun] = useState<boolean>(false);

  const dataForRun: RunCodeType = {
    language_id: content.language.id,
    source_code: content.code,
    stdin: content.input,
  };

  const pollSubmission = useCallback(() => {
    if (!submissionId) return;

    apiService
      .get(`/code/status/${submissionId}`)
      .then((res: any) => {
        const { status, stdout, stderr, message, output, compile_output } = res;
        setStatus(status);
        if (status === "In Queue" || status === "Processing") {
          setTimeout(pollSubmission, 1000); // Poll every 1 second
        } else {
          setResult(
            compile_output ||
              output ||
              stdout ||
              stderr ||
              message ||
              "No output"
          );
        }
      })
      .catch((err) => {
        console.error(err);
        setStatus("Error");
        setResult("An error occurred while processing your code.");
      });
  }, [submissionId]);

  const runCode = useCallback(() => {
    setHasRun(true);
    apiService
      .post("/code/run", dataForRun)
      .then((res: any) => {
        setSubmissionId(res.submissionId);
      })
      .catch((err) => {
        console.error(err);
        setStatus("Error");
        setResult("Failed to submit your code.");
      });
  }, [dataForRun]);

  useEffect(() => {
    if (submissionId) {
      pollSubmission();
    }
  }, [submissionId, pollSubmission]);

  useEffect(() => {
    if (open && !hasRun) {
      setStatus("Submitting...");
      setResult("");
      runCode();
    }
  }, [open, hasRun, runCode]);

  useEffect(() => {
    if (!open) {
      setHasRun(false);
    }
  }, [open]);

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      sx={{ width: "50%", maxHeight: "30vh", overflow: "auto" }}
    >
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          bgcolor: "#f9f9f9",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
              {(statusIcons as Record<string, React.ReactNode>)[status] || (
                <Error color="error" />
              )}
            </Box>
            <Typography variant="h6" component="div">
              {status}
            </Typography>
          </Box>

          <IconButton onClick={onClose} color="inherit">
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          Result:
        </Typography>
        <Box
          sx={{
            mt: 2,
            p: 2,
            bgcolor: "white",
            borderRadius: 2,
            boxShadow: 1,
            whiteSpace: "pre-wrap",
            border: "1px solid #ddd",
            flex: 1,
            overflow: "auto",
            maxHeight:"30vh"
          }}
        >
          {result}
        </Box>
      </Box>
    </Drawer>
  );
};

export default OutputDrawer;
