import React from "react";
import Editor from "@monaco-editor/react";
import { Box } from "@mui/material";
import { Language, runCodeData } from "../../constants/EditorUtils";

interface EditorPaneProps {
  language: Language;
  theme: string;
  code: string;
  drawerContent:runCodeData,
  SetCode: React.Dispatch<React.SetStateAction<string>>;
  SetDrawerContent: React.Dispatch<React.SetStateAction<runCodeData>>;
}
const EditorPane: React.FC<EditorPaneProps> = ({ language, theme, code, SetCode, drawerContent, SetDrawerContent }) => (
  <Box
    sx={{
      flex: 1,
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      overflow: "hidden", // Prevent overflow
      height: "100%", // Set a fixed height
      width: "100%", // Set a fixed width
    }}
  >
    <Editor
      width="100%"
      height="100%"
      language={language.value}
      theme={theme}
      value={code}
      onChange={(value) => {
        SetCode(value || "");
        SetDrawerContent({
         ...drawerContent,
         code: value || "",
        });
      }}
      options={{
        selectOnLineNumbers: true,
        minimap: { enabled: false },
        fontSize: 14,
        automaticLayout: true,
        scrollBeyondLastLine: false,
        wordWrap: "on",
        smoothScrolling: true,
      }}
    />
  </Box>
);export default EditorPane;
