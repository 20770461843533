import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  SelectChangeEvent,
  Badge,
  Avatar,
  Button,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { apiService } from "../../../services/Service";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../../utils/types";
import { handleAuthError } from "../../../utils/authUtils";
import { tagOptions } from "../../../constants/tagOptions"; // Adjust if needed
import useDebounce from "../../../customHooks/useDebounce";

const MCQs: React.FC = () => {
  const [mcqs, setMCQs] = useState<any[]>([]); // Replace `any` with your MCQ type
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [search, setSearch] = useState<string>("");
  const [difficulty, setDifficulty] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalMCQs, setTotalMCQs] = useState<number>(0);

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    const fetchMCQs = async () => {
      setLoading(true);
      try {
        const params = new URLSearchParams({
          page: page.toString(),
          limit: rowsPerPage.toString(),
          search: debouncedSearch,
          difficulty,
          tags: selectedTags.join(","),
        }).toString();

        const response = await apiService.get<{
          mcqs: any[]; // Replace with your MCQ type
          totalMCQs: number;
        }>(`/mcq?${params}`);
        setMCQs(response.mcqs);
        setTotalMCQs(response.totalMCQs);
      } catch (err) {
        setError("Failed to fetch MCQs");
        const error = err as AxiosError<ErrorResponse>;
        const errorResponse = error.response?.data;

        if (errorResponse) {
          handleAuthError({ error: errorResponse.error }, window.location.href);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchMCQs();
  }, [page, rowsPerPage, debouncedSearch, difficulty, selectedTags]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleDifficultyChange = (event: SelectChangeEvent<string>) => {
    setDifficulty(event.target.value);
  };

  const handleTagChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedTags(event.target.value as string[]);
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  if (error)
    return (
      <Box textAlign="center" color="error.main" mt={4}>
        {error}
      </Box>
    );

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        MCQs
      </Typography>
      <Box mb={2} display="flex" gap={2} flexWrap="wrap" alignItems="center">
        <TextField
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          label="Search"
          placeholder="Search by title"
          sx={{ flex: 1, minWidth: 200 }}
        />
        <FormControl variant="outlined" sx={{ flex: 1, minWidth: 150 }}>
          <InputLabel>Difficulty</InputLabel>
          <Select
            value={difficulty}
            onChange={handleDifficultyChange}
            label="Difficulty"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="easy">Easy</MenuItem>
            <MenuItem value="medium">Medium</MenuItem>
            <MenuItem value="hard">Hard</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" sx={{ flex: 2, minWidth: 200 }}>
          <InputLabel>Tags</InputLabel>
          <Select
            multiple
            value={selectedTags}
            onChange={handleTagChange}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {(selected as string[]).map((tag) => (
                  <Chip key={tag} label={tag} />
                ))}
              </Box>
            )}
            label="Tags"
          >
            {tagOptions.map((tag) => (
              <MenuItem key={tag} value={tag}>
                {tag}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Tags</TableCell>
              <TableCell>Difficulty</TableCell>
              <TableCell>Author</TableCell>
              <TableCell>Picture</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mcqs?.map((mcq) => (
              <TableRow key={mcq.id}>
                {" "}
                {/* Replace with your unique identifier */}
                <TableCell>
                  <Tooltip
                    title={
                      <div dangerouslySetInnerHTML={{ __html: mcq.question }} />
                    }
                    placement="top-start"
                  >
                    <Typography variant="subtitle1" fontWeight="bold">
                      {mcq._id}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    {mcq.tags.map((tag: string) => (
                      <Chip
                        key={tag}
                        label={tag}
                        color="primary"
                        size="small"
                      />
                    ))}
                  </Box>
                </TableCell>
                <TableCell>
                  <Badge
                    badgeContent={mcq.difficultyLevel}
                    color={
                      mcq.difficultyLevel === "easy"
                        ? "success"
                        : mcq.difficultyLevel === "medium"
                        ? "warning"
                        : "error"
                    }
                  />
                </TableCell>
                <TableCell>{mcq.author.fullname}</TableCell>
                <TableCell>
                  <Avatar src={mcq.author.picture} alt={mcq.author.fullname} />
                </TableCell>
                <TableCell>
                  <Button
                    component={Link}
                    to={`/mcq/update/${mcq._id}`} // Use dynamic routing to the MCQ page
                    target="_blank"
                    variant="contained"
                    color="primary"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{ color: "white" }}
                    >
                      Edit MCQ
                    </Typography>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalMCQs}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Box>
  );
};

export default MCQs;
